export const environment = {
  name: 'DEMO',
  production: true,
  traceSession: true,
  kcRealmUrl: 'https://auth1.wastics.eu/',
  kcRealm: 'WasticsDemo',
  apiUrl: 'https://dwrmdemo9f4xyc4.wastics.eu/awk-service/api/v1',
  awkExcelApi: 'https://excel9vb78nuh.wastics.eu/v1/data',
  puppeteerApiUrl: 'https://pdff7qz5ptu.wastics.eu',
  quarkusApiUrl: 'https://dwrmdemo9f4xyc4.wastics.eu/v1',
  matchMakingApiUrl: 'https://mmdemo9f4xyc4.wastics.eu',
  notifications: {
    publicKey:
      'BBnwmQypS5YQB9ICV8UEmLZQKzg9jDvQFuF_jBl2NzJApW-2MTYSw-UKdw3e7PvBrWOSIQroTebIxyJySo-qyoo',
  },
};
